import { request } from './request.js'
import qs from 'qs'

export function postLogin (method, datas) {
  return request({
    url: '/admin/login',
    method,
    data: qs.stringify(datas)
  })
}
