<template>
  <div id="Login">
    <div class="box">
      <div class="formBox">
        <div class="leftbox">
          <div class="logo">
            <img src="../../assets/img/唐龙视界logo_画板 1.svg" alt="">
            <!-- <div>广东观视界</div> -->
          </div>
        </div>

        <div class="rightbox">
          <div class="title">数字旅游产业互联网平台</div>
          <!-- 登录表单 -->
          <el-form :model="loginForm" :rules="rules">
            <el-form-item prop="username">
              <el-input placeholder="用户" prefix-icon="el-icon-user" v-model="loginForm.username">
              </el-input>
            </el-form-item>

            <el-form-item prop="password">
              <el-input placeholder="密码" prefix-icon="el-icon-unlock" show-password v-model="loginForm.password"
                @keyup.enter.native="login(loginForm)">
              </el-input>
            </el-form-item>
            <div class="metpassword">
              <div class="remember-password">
                <input type="checkbox"><span>记住密码</span>
              </div>
              <span class="forget-password">忘记密码?</span>
            </div>

            <el-form-item>
              <el-button type="primary" size="medium" @click="login(loginForm)">登 录</el-button>
            </el-form-item>

          </el-form>
        </div>
      </div>
    </div>
    <div class="bottom">
      <!-- <div class="one">
        <img src="../../assets/img/2.png"
             alt="">
      </div> -->
      <div class="two">
        <img src="../../assets/img/1.png" alt="">
      </div>
    </div>
    <div class="word">
      <ul>
        <li>广东观视界网络科技有限公司</li>
        <li>沪ICP16035589号-3</li>
        <li>联系我们</li>
        <li>隐私声明</li>
        <li>法律条款</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { postLogin } from 'api/login.js'
import { mapMutations } from 'vuex'

export default {
  name: 'Login',
  data() {
    return {
      loginForm: {
        username: 'admin',//admin
        password: 'GuanSj22770280',//GuanSj22770280
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          {
            min: 3,
            max: 15,
            message: '长度在 3 到 15 个字符',
            trigger: 'blur'
          }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 3,
            max: 15,
            message: '长度在 3 到 15 个字符',
            trigger: 'blur'
          }
        ]
      },

      // 全部菜单
      allMenu: []
    }
  },
  mounted() { },
  computed: {},

  methods: {
    // 登录用户密码 点击登录
    login(val) {
      postLogin('POST', val).then((res) => {
        console.log(res, '登录结果');
        const resData = res.data
        console.log(res, '登录结果');
        if (resData.code === 200) {
          this.$message({
            message: resData.message,
            type: 'success'
          })
          // 授权 信息 保存到 sessionStorage 中
          window.sessionStorage.setItem(
            'authorization',
            resData.data.authorization
          )
          console.log(resData.data.authorization, 'authorization登录凭据')

          // 获取菜单
          this.allMenu = this.menuDataFormat(resData.data.admin_user.rbac)//从登录结果中获取当前账户可展示的菜单

          // 添加页面(会员板块)
          let arrList = [
            {
              code: 'viplist',
              icon: 'viplist',
              id: '10203',
              label: '会员类别',
              name: 'hylb',
              parent_id: '102',
              path: '/MemberCategory',
              permission: []
            },
            {
              code: 'viplist',
              icon: 'viplist',
              id: '10205',
              label: '权益管理',
              name: 'qygl',
              parent_id: '102',
              path: '/MemberEquity',
              permission: []
            }
          ]

          this.allMenu.forEach(item => {
            if (item.label == "会员管理") {
              item.children.push(...arrList);
            }
          })

          this.allMenu.forEach((item, index) => {
            if (index == 0) {
              console.log('item', item)
              item.children[0].name = '/comment_shop'
              item.children[0].path = '/comment_shop'
              item.children[1].name = '/comment_hotel'
              item.children[1].path = '/comment_hotel'
            }
          })
          let dianjing_member = {
            children: [
              {
                icon: "22",
                id: 11111,
                label: "会员管理",
                name: "EsportsMember_manage",
                path: "/EsportsMember_manage",
              },
              {
                icon: "22",
                id: 11112,
                label: "等级管理",
                name: "EsportsMember_level",
                path: "/EsportsMember_level",
              },
              {
                icon: "22",
                id: 11113,
                label: "会员配置",
                name: "EsportsMember_config",
                path: "/EsportsMember_config",
              },
              {
                icon: "22",
                id: 11114,
                label: "生日礼包",
                name: "EsportsMember_birthday",
                path: "/EsportsMember_birthday",
              },
              {
                icon: "22",
                id: 11115,
                label: "传奇会员",
                name: "EsportsMember_legendary",
                path: "/EsportsMember_legendary",
              },
              {
                icon: "22",
                id: 11115,
                label: "营销工具",
                name: "MarketingTools",
                path: "/MarketingTools",
              },
            ],
            icon: "22",
            id: 1111,
            label: "电竞会员",
            name: "/EsportsMember",
            path: "/EsportsMember",
          }
          this.allMenu.push(dianjing_member);
          console.log('菜单列表:', this.allMenu)

          //END 添加页面(会员板块)

          this.getAllMenu(this.allMenu)

          // 跳转到首页
          this.$router.push({ path: '/productManage/hotelHomeStay' })
        } else {
          return this.$message({
            message: resData.message,
            type: 'error'
          })
        }
      })
    },

    ...mapMutations({
      getAllMenu: 'getAllMenu'
    }),

    // 数据处理
    menuDataFormat(arr) {
      const data = []
      for (const item of arr) {
        const cArr = []
        for (const cItem of item.child) {
          cArr.push({
            id: cItem.id,
            label: cItem.name,
            name: cItem.code,
            path: cItem.path,
            icon: cItem.icon
          })
        }
        data.push({
          id: item.id,
          label: item.name,
          name: item.code,
          path: item.path,
          icon: item.icon,
          children: cArr
        })
      }

      return data
    }
  }
}
</script>

<style lang="scss" scoped>
$text-back: #fff;

#Login {
  position: relative;
  height: 100%;
  width: 100%;
  // background: rgba(22, 137, 228, 0.8);
  background: $text-back;
}

.box {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  z-index: 100;

  width: 760px;
  height: 345px;
  // text-align: center;
}

.formBox {
  // margin: 40px 60px;
  // padding: 20px 20px 1px;
  background: #ffffff;
  box-shadow: 0px 4px 15px 10px rgba(190, 190, 190, 0.17);
  border-radius: 16px;
  width: 100%;
  height: 100%;
  padding-top: 15px;

  .leftbox {
    width: 380px;
    height: 360px;
    position: relative;

    .logo {
      position: absolute;
      top: 25%;
      left: 25%;
      text-align: center;
      font-size: 18px;
      color: #606266;
      font-family: SimHei;

      img {
        width: 150px;
        height: 100px;
      }
    }
  }

  .rightbox {
    width: 380px;
    margin-left: 363px;
    margin-top: -325px;
    border-left: 1px solid #e4e7ed;
    padding-left: 49px;

    .metpassword {
      width: 280px;
      font-size: 14px;
      color: #303133;
      margin-bottom: 21px;

      .remember-password {
        display: inline-block;
        position: relative;

        input {
          position: absolute;
          top: 1px;
        }

        span {
          margin-left: 18px;
        }
      }

      .forget-password {
        float: right;
        color: #409eff;
        cursor: pointer;
      }
    }
  }

  .title {
    width: 198px;
    height: 25px;
    font-size: 18px;
    font-family: Helvetica;
    color: #606266;
    line-height: 22px;
  }
}

.bottom {
  position: absolute;
  bottom: 0%;
  width: 100%;

  .one {
    bottom: 0%;
    position: absolute;

    img {
      width: 100%;
      height: 20%;
    }
  }

  .two {
    img {
      width: 100%;
      height: 20%;
    }
  }

  // .two {
  //   width: 100%;
  //   height: 30%;
  //   background: linear-gradient(
  //       273deg,
  //       #8cf6dc 0%,
  //       rgba(127, 249, 238, 0.63) 100%
  //     ),
  //     radial-gradient(transparent 50px, black 51px, transparent 52px);
  //   position: absolute;
  //   bottom: 0%;
  // }
}

.word {
  position: absolute;
  bottom: 10%;
  left: 36%;
  color: black;

  ul {
    width: 588px;
    font-size: 14px;

    li {
      float: left;
      list-style: none;
      margin-left: 5px;
      padding-right: 5px;
      border-right: 1px solid white;
      cursor: pointer;
    }
  }
}

.el-form {
  margin-top: 21px;
}

.el-form-item {
  /deep/ .el-input__inner:focus {
    border-color: #dcdfe6;
  }
}

.el-input {
  width: 280px;
}

.el-button {
  width: 280px;
}
</style>
